










































































































































































































import {
computed,
defineComponent,
onBeforeMount,
ref,
useContext,
useRouter,
watch,
} from '@nuxtjs/composition-api';
import {
SfCheckbox,
SfInput,
SfSelect,
} from '@storefront-ui/vue';
import omitDeep from 'omit-deep';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { max, oneOf, required } from 'vee-validate/dist/rules';
import { PropType } from 'vue';
import BKButton from '~/components/BK/BKButton.vue';
import type { Countries, Country, UseCountrySearchParams } from '~/composables';
import { CountryCodeEnum, useCountrySearch } from '~/composables';
import type { TransformedCustomerAddress } from '~/modules/customer/composables/types';
import { useUser } from '~/modules/customer/composables/useUser';
import addressGetter from '~/modules/customer/getters/addressGetter';
import userGetters from '~/modules/customer/getters/userGetters';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('max', {
  ...max,
  message: 'The field should have at maximum 40 characters',
});

extend('oneOf', {
  ...oneOf,
  message: 'Invalid country',
});

export default defineComponent({
  name: 'AddressForm',

  components: {
    SfInput,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    BKButton,
  },
  props: {
    hasShippingAndBillingCheckboxes: {
      type: Boolean,
      default: true,
    },
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      default: () => ({
        city: '',
        country_code: '' as CountryCodeEnum,
        firstname: '',
        lastname: '',
        postcode: '',
        street: '',
        telephone: '',
        default_shipping: false,
        default_billing: false,
        company: '',
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const router = useRouter();
    const context = useContext();
    const { load: loadCountries, search: searchCountry } = useCountrySearch();
    const { user } = useUser();

    const form = ref<TransformedCustomerAddress | null>(null);

    const countries = ref<Countries[]>([]);
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const country = ref<Country | null>(null);

    const updateCountry = async (params: UseCountrySearchParams) => {
      country.value = await searchCountry(params);
    };

    watch(() => props.address, () => {
      form.value = {
        city: props.address.city,
        country_code: props.address.country_code,
        firstname: props.edit ? props.address.firstname : userGetters.getFirstName(user.value),
        lastname: props.edit ? props.address.lastname : userGetters.getLastName(user.value),
        postcode: props.address.postcode,
        street: props.address.street,
        telephone: props.address.telephone,
        default_shipping: props.address.default_shipping || false,
        default_billing: props.address.default_billing || false,
        company: props.address.company || '',
      } as unknown as TransformedCustomerAddress;
    }, { immediate: true });

    const submitForm = () => {
      emit('submit', {
        form: omitDeep(form.value, ['__typename']),
        // TODO: Handle Error
        onError: () => {},
      });
    };

    const goToAddressesOverview = () => {
      router.push(context.localeRoute({ name: 'customer-addresses-details' }));
    };

    onBeforeMount(async () => {
      countries.value = await loadCountries();
      if (props.address.country_code) {
        country.value = await searchCountry({ id: props.address.country_code });
      }
    });

    return {
      form,
      submitForm,
      countriesList,
      updateCountry,
      goToAddressesOverview,
    };
  },
});
