var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"id":"shipping-details-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|max:40","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"firstname","label":_vm.$t('First Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|max:40","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"lastname","label":_vm.$t('Last Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"company","rules":"max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"company","label":_vm.$t('Company'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|max:40","name":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"street","label":_vm.$t('Street name and house number'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|max:40","name":"postcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"postcode","label":_vm.$t('Zip-code'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"city","label":_vm.$t('City'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"country_code","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfSelect',{staticClass:"form__select sf-select--underlined",attrs:{"label":_vm.$t('Country'),"name":"country","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},on:{"input":function($event){return _vm.updateCountry({ id: $event })}},model:{value:(_vm.form.country_code),callback:function ($$v) {_vm.$set(_vm.form, "country_code", $$v)},expression:"form.country_code"}},[(!_vm.edit)?_c('SfSelectOption',{attrs:{"value":''}}):_vm._e(),_vm._v(" "),_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.abbreviation}},[_vm._v("\n            "+_vm._s(countryOption.label)+"\n          ")])})],2)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|max:40","name":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"telephone","label":_vm.$t('Phone number'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}],null,true)})],1),_vm._v(" "),(_vm.hasShippingAndBillingCheckboxes)?_c('div',[_c('SfCheckbox',{staticClass:"form__checkbox-isDefaultShipping",attrs:{"name":"isDefaultShipping","label":_vm.$t('Set as default shipping'),"disabled":_vm.loading},model:{value:(_vm.form.default_shipping),callback:function ($$v) {_vm.$set(_vm.form, "default_shipping", $$v)},expression:"form.default_shipping"}}),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__checkbox-isDefaultBilling",attrs:{"name":"isDefaultBilling","label":_vm.$t('Set as default billing'),"disabled":_vm.loading},model:{value:(_vm.form.default_billing),callback:function ($$v) {_vm.$set(_vm.form, "default_billing", $$v)},expression:"form.default_billing"}})],1):_vm._e(),_vm._v(" "),_vm._t("actions",function(){return [_c('div',{staticClass:"lg:flex"},[_c('BKButton',{staticClass:"action-button",attrs:{"type":"submit","disabled":_vm.loading},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.edit ? _vm.$t('Edit address') : _vm.$t('Add address'))+"\n          ")]},proxy:true}],null,true)}),_vm._v(" "),_c('BKButton',{staticClass:"action-button cancel-button",attrs:{"disabled":_vm.loading,"variant":"secondary"},on:{"click":_vm.goToAddressesOverview},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('Cancel'))+"\n          ")]},proxy:true}],null,true)})],1)]})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }